import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// components
import RequireAuth from "./components/ProtectedRoute";
import NotFound from "./components/NotFound";
//css
import "./Pages/Login/login.css";
import "./Pages/AddQuestion/addQuestion.css";
import "./Pages/CampaignManager/Campaign.css";
import "./Pages/Review/review.css";
import "./Pages/NewCampaign/newcampaign.css";
import "./Pages/CampaignDetails/campaigndetails.css";
import "./Pages/Calendar/Calendar.css";
import "./Pages/Dashboard/dashboard.css";

// pages
const Login = lazy(() => import("./Pages/Login"));
const Signup = lazy(() => import("./Pages/Signup"));
const ForgotPassword = lazy(() => import("./Pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword"));
const EmailVerification = lazy(() => import("./Pages/EmailVerification"));
const AddQuestion = lazy(() => import("./Pages/AddQuestion"));
const CampaignManager = lazy(() => import("./Pages/CampaignManager"));
const ReviewCampaign = lazy(() => import("./Pages/Review"));
const NewCampaign = lazy(() => import("./Pages/NewCampaign"));
const CampaignDetails = lazy(() => import("./Pages/CampaignDetails"));
const CampaignCalendar = lazy(() => import("./Pages/Calendar"));
const Wallet = lazy(() => import("./Pages/Wallet"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const Payments = lazy(() => import("./Pages/Payments"));
const TrafficAnalyzer = lazy(() => import("./Pages/TrafficAnalyzer"));
const Profile = lazy(() => import("./Pages/Profile"));
const GST = lazy(() => import("./Pages/GST"));
const PaymentStatusMessage = lazy(() =>
  import("./components/Payment/PaymentMessage")
);
const EndedCampaignManager = lazy(() =>
  import("./Pages/CampaignManager/EndedCampaigns")
);

function App() {
  return (
    <>
      <Suspense
        fallback={
          <div className="h-screen w-100 d-flex align-items-center justify-content-center">
            <div>
              <div className="page_loading_img"></div>
              <div className="loading-dots">
                <div className="loading-dots--dot"></div>
                <div className="loading-dots--dot"></div>
                <div className="loading-dots--dot"></div>
              </div>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verify-otp" element={<EmailVerification />} />

          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/traffic-analyzer" element={<TrafficAnalyzer />} />
            <Route path="/add-question" element={<AddQuestion />} />
            <Route path="/campaign/calendar" element={<CampaignCalendar />} />
            <Route path="/campaign/manager" element={<CampaignManager />} />
            <Route
              path="/campaign/manager/old"
              element={<EndedCampaignManager />}
            />
            <Route
              path="/campaign/manager/:campaignId"
              element={<CampaignDetails />}
            />
            <Route path="/campaign/review" element={<ReviewCampaign />} />
            <Route path="/campaign/new" element={<NewCampaign />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/verify" element={<GST />} />
            <Route
              path="/payment/status/:orderId"
              element={<PaymentStatusMessage />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
