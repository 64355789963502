import { Navigate, Outlet } from "react-router-dom";

const RequireAuth = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const status = localStorage.getItem("loginStatus");
  const isEmailVerified = localStorage.getItem("isEmailVerified");

  if (!refreshToken || !status || isEmailVerified !== "true") {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default RequireAuth;
