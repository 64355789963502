import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen w-100 d-flex align-items-center justify-content-center bg-white">
      <div className="text-center">
        <img src="/logo.png" className="logo_size" />
        <p className="f-23 font-bold m-0 mt-3">
          Sorry! The page you are looking for doesn't exists.
        </p>

        <Link to={"/dashboard"} className="">
          <button className="btn bg-primary text-white px-4 py-2 mt-2">
            Go to Homepage
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
